import React, { useEffect, useState, useMemo  } from 'react'
import Select from 'react-select'
import CustomDataTable from '../../CustomDataTable'
import CustomModal from '../../common/customModal';
import { RequestCancelOrRequestCancelDetails } from './requestCancelOrRequestCancel';
import CustomLoading from '../../CustomLoading';
import { useSendMoney } from '../../../hooks/useSendMoney';

const ExpandedRow = ({ data, showCancelButton, handleCancelClick, t }) => {
  return (
    <div className='p-4 col-md-4' style={{ fontSize: '14px' }}>
      <div className='d-flex justify-content-between align-items-center'>
        <span className='fw-bold'>Reference: </span>
        <span>{data.pickup_code}</span>
      </div>
      <div className='d-flex justify-content-between align-items-center'>
        <span className='fw-bold'>Partner: </span>
        <span>{data.partner_name}</span>
      </div>
      <div className='d-flex justify-content-between align-items-center'>
        <span className='fw-bold'>Sender: </span>
        <span>{data.sender_name}</span>
      </div>
      <div className='d-flex justify-content-between align-items-center'>
        <span className='fw-bold'>Country: </span>
        <span>{data.country}</span>
      </div>
      <div className='d-flex justify-content-between align-items-center'>
        <span className='fw-bold'>Amount: </span>
        <span>{data.amount}</span>
      </div>
      <div className='d-flex justify-content-between align-items-center'>
        <span className='fw-bold'>Status: </span>
        <span>{data.status}</span>
      </div>
      <div className='d-flex justify-content-between align-items-center'>
        <span className='fw-bold'>Beneficiary: </span>
        <span>{data.receiver_name}</span>
      </div>
      <div className='d-flex justify-content-between align-items-center'>
        <span className='fw-bold'>Total Amount: </span>
        <span>{data.sending_total_amount}</span>
      </div>
      <div className='d-flex justify-content-between align-items-center'>
        <span className='fw-bold'>Receiving Option: </span>
        <span>{data.receiving_option}</span>
      </div>
      <div className='d-flex justify-content-between align-items-center'>
        <span className='fw-bold'>Date: </span>
        <span>{data.created_at}</span>
      </div>
      {showCancelButton && (
        <div className='d-flex justify-content-between align-items-center mt-3'>
          <span className='fw-bold'>{t('ACTIONS')}</span>
          {data.transaction.cancellation_requested === 1 ? (
            <span className='badge bg-danger text-white p-2'>{t('CANCELLATION_REQUESTED')}</span>
          ) : (
            <button
              className='btn btn-danger btn-sm'
              type='button'
              onClick={() => handleCancelClick()}
            >
              {t('CANCEL')}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

const SendMoney = () => {

  const [status, setStatus] = useState([])
  const [duration, setDuration] = useState('1')
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  const [receivingCountries, setReceivingCountries] = useState([])
  const [countries, setCountries] = useState([])
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  
  const {
    handleCancelClick,
    cancelOrRequestCancel,
    t,
    toggleModal,
    setLoading,
    isCancelable,
    loading,
    showModal,
    formikRef,
    httpClient
  } = useSendMoney();

  const transactionStatuses = [
    { value: 1, label: t('PENDING') },
    { value: 2, label: t('IN_PROGRESS') },
    { value: 3, label: t('PAID') },
    { value: 4, label: t('REJECTED') },
    { value: 5, label: t('CANCELLED') },
    { value: 6, label: t('TRANSMITTED') },
    { value: 7, label: t('FAILED') },
    { value: 8, label: t('CREATED') },
    { value: 9, label: t('ON_HOLD') },
    { value: 10, label: t('UNDER_REVIEW') },
    { value: 11, label: t('EXPIRED') },
    { value: 12, label: t('REJECTED_BY_COMPLIANCE') },
  ]

  const transactionDurations = [
    { value: '1', label: t('TODAY') },
    { value: '2', label: t('THIS_WEEK') },
    { value: '3', label: t('THIS_MONTH') },
    { value: '4', label: t('PREVIOUS_WEEK') },
    { value: '5', label: t('PREVIOUS_MONTH') },
    { value: '6', label: t('CUSTOM_DATE') },
  ]

  const getCurrentDate = () => {
    const today = new Date()
    return today.toISOString().split('T')[0]
  }

  const handleFromDateChange = (e) => {
    const selectedDate = e.target.value
    setFromDate(selectedDate)

    const fromDateObject = new Date(selectedDate)
    const autoToDateObject = new Date(
      fromDateObject.getFullYear(),
      fromDateObject.getMonth(),
      fromDateObject.getDate() + 30
    )
    const autoToDate = autoToDateObject.toISOString().split('T')[0]
    setToDate(autoToDate)
  }

  const renderExpandedRow = useMemo(() => {
    return (row) => {
        setSelectedTransaction(row);
        return <ExpandedRow data={row} showCancelButton={isCancelable(row.status)} handleCancelClick={handleCancelClick} t={t} />;
    };
  }, []); 

  const updateTransactionStatus = () => {
    setData((prevData) =>
      prevData.map((item) =>
        item.id === selectedTransaction.id
          ? { ...item, transaction: { ...item.transaction, cancellation_requested: 1 } }
          : item
      )
    );
  };


  const fetchData = async () => {
    if (duration === '6' && (!fromDate || !toDate)) {
      return
    }

    setLoading(true)
    try {
      const response = await httpClient.post(
        '/partner/transactions',
        {
          is_sent: 1,
          status: status.map((s) => s.value),
          receiving_country: receivingCountries.map((s) => s.value),
          duration,
          fromDate: duration === '6' ? fromDate : undefined,
          toDate: duration === '6' ? toDate : undefined,
        });
      console.log('response', response.data.data)
      setData(response.data.data)
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }

  const fetchCountries = async () => {
    setLoading(true)
    try {
      const response = await httpClient.get('/sendingCountries');
      setCountries(response.data)
    } catch (error) {
      console.error('Error fetching countries:', error)
    } finally {
      setLoading(false)
    }
  }

  const defineColumns = () => {
    const windowWidth = window.innerWidth

    const allColumns = [
      {
        name: '#',
        selector: (row) => row.DT_RowIndex,
        sortable: true,
        width: '60px',
        exportable: false,
      },
      {
        name: 'Reference',
        selector: (row) => row.pickup_code,
        cell: (row) => (
          <a href={`/send-money/${row.pickup_code}`}>{row.pickup_code}</a>
        ),
        width: '180px',
        searchable: true,
      },
      {
        name: 'Partner',
        selector: (row) => row.partner_name,
        hideOnMobile: true,
        sortable: true,
        width: '100px',
        searchable: true,
      },
      {
        name: 'Sender',
        selector: (row) => row.sender_name,
        hideOnMobile: true,
        sortable: true,
        width: '160px',
        searchable: true,
      },
      {
        name: 'Country',
        selector: (row) => row.country,
        width: '100px',
        searchable: true,
      },
      {
        name: 'Amount',
        selector: (row) => row.amount,
      },
      {
        name: 'Status',
        selector: (row) => row.status,
        hideOnMobile: true,
        width: '150px',
      },
      {
        name: 'Beneficiary',
        selector: (row) => row.receiver_name,
        hideOnMobile: true,
        sortable: true,
        width: '180px',
        searchable: true,
      },
      {
        name: 'Total Amount',
        selector: (row) => row.sending_total_amount,
        hideOnMobile: true,
        width: '150px',
      },
    ]

    // Filter columns based on screen width
    const filteredColumns = allColumns.filter(
      (column) => !(column.hideOnMobile && windowWidth < 768)
    )

    setColumns(filteredColumns)
  }

  useEffect(() => {
    fetchCountries()
  }, [])

  useEffect(() => {
    fetchData()
    defineColumns()
    window.addEventListener('resize', defineColumns)

    return () => {
      window.removeEventListener('resize', defineColumns)
    }
  }, [status, duration, fromDate, toDate, receivingCountries])

  return (
    <div style={{ padding: '20px' }}>
      <div className='card'>
        <div className='card-header'>
          <h5>{t('SENT_TRANSACTIONS')}</h5>
        </div>
        <div className='card-body'>
          <form className='mb-5'>
          <div className='form-group row justify-content-md-center'>
              <div className='col-md-4'>
                <label htmlFor='status'>{t('STATUS')}</label>
                <Select
                  options={transactionStatuses}
                  closeMenuOnSelect={false}
                  placeholder={t('ALL_STATUSES')}
                  isMulti
                  onChange={setStatus}
                />
              </div>
              <div className='col-md-4'>
                <label htmlFor='source'>{t('RECEIVING_COUNTRIES')}</label>
                <Select
                  options={countries.map((country) => {
                    return {
                      value: country.country_code,
                      label: country.country_name,
                    }
                  })}
                  closeMenuOnSelect={false}
                  isMulti
                  placeholder={t('ALL')}
                  onChange={setReceivingCountries}
                />
              </div>
              <div className='col-md-4'>
                <label htmlFor='duration'>{t('DURATION')}</label>
                <select
                  className='form-select'
                  name='duration'
                  id='duration'
                  value={duration}
                  onChange={(e) => setDuration(e.target.value)}
                >
                  {transactionDurations.map((d, index) => (
                    <option key={index} value={d.value}>
                      {d.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {duration === '6' && (
              <div className='form-group row justify-content-md-center mt-4'>
                <div className='col-md-3'>
                  <label htmlFor='from_date'>{t('FROM_DATE')}</label>
                  <input
                    className='form-control'
                    type='date'
                    id='from_date'
                    value={fromDate || ''}
                    onChange={handleFromDateChange}
                    max={getCurrentDate()}
                  />
                </div>
                <div className='col-md-3'>
                  <label htmlFor='to_date'>{t('TO_DATE')}</label>
                  <input
                    className='form-control'
                    type='date'
                    id='to_date'
                    value={toDate || ''}
                    disabled
                  />
                </div>
              </div>
            )}
          </form>

          <CustomDataTable
            columns={columns}
            data={data}
            exportFilename={`transactions_${new Date()
              .toISOString()
              .replace(/[-T:.Z]/g, '')}
              .slice(0, 14)}.csv`}
            exportable
            searchable
            expandableRows
            expandableRowExpanded={(row) => row.expanded}
            expandableRowsComponent={({ data }) => renderExpandedRow(data)}
          />
        </div>
      </div>

      {selectedTransaction && (
        <CustomModal
          showModal={showModal}
          title={t('CANCELLATION_REQUEST')}
          onClose={toggleModal}
          onSubmit={() => formikRef.current.submitForm()}
          submitButtonText={t('SUBMIT')}
          t={t}
          modalChildrenBody={
            loading ? (
              <div className="text-center">
                <CustomLoading />
              </div>
            ) : (
              <RequestCancelOrRequestCancelDetails
                t={t}
                selectedTransaction={selectedTransaction}
                cancelOrRequestCancel={cancelOrRequestCancel}
                updateTransactionStatus={updateTransactionStatus}
                toggleModal={toggleModal}
                formikRef={formikRef}
              />
            )
          }
        />
      )}

      {loading && (
        <CustomLoading/>
      )}
      
    </div>
  )
}

export default SendMoney
